
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'FooterComponent',
  setup () {
    const footerType = ref<number | null>(null)
    const route = useRoute()
    const path = ref()
    const clientType = process.env.VUE_APP_CLIENT_TYPE as string

    const viewControl = (type: number) => {
      if (type === footerType.value) {
        footerType.value = null
      } else {
        footerType.value = type
      }
    }
    watch(route, () => {
      path.value = route.path
    })
    onMounted(() => {
      path.value = route.path
    })
    return {
      path,
      footerType,
      clientType,
      viewControl
    }
  }
}
